<template>
  <div class="separate-inputs max-w-[280px]">
    <div class="flex justify-center w-full">
      <div class="flex gap-x-2">
        <template v-for="(input, index) in inputsMapping">
          <input
            :ref="index"
            :key="index"
            :autofocus="index === 0"
            :type="input.type"
            :placeholder="input.placeholder"
            class="input"
            :class="{
              'border-error text-error': errorMessages.length > 0,
              'border-success':
                isValid && inputsValue.length >= inputsMapping.length,
            }"
            :name="`inputvalue${index}`"
            autocomplete="off"
            :value="inputsValue[index]"
            maxlength="1"
            @input="onCodeInput"
            @keydown.backspace="$event.target.value || onCodeBackspace(index)"
          />
        </template>
      </div>
    </div>
    <input
      ref="inputAutofill"
      :type="inputsMapping[0].type"
      class="separate-inputs__autofill"
      :class="{
        hide: inputsValue !== '',
      }"
      name="postal"
      :maxlength="inputsMapping.length"
      @input="onInputAutofill"
    />
  </div>
</template>
<script>
import AbstractFormField from 'chimera/all/components/form/fields/AbstractFormField'
import ValidationError from 'chimera/all/functions/ValidationError'
import ValidationRules from 'chimera/all/functions/ValidationRules'

export const field = 'postal'

export default {
  name: 'SeparateInputs',

  extends: AbstractFormField,

  props: {
    field: {
      type: String,
      default: field,
    },

    errorMessages: {
      type: Array,
      default: () => [],
    },

    inputsMapping: {
      type: Array,
      default: () => [],
    },
  },

  /**
   * @returns {{ inputValid, inputsValue }}
   */
  data() {
    return {
      value: '',
      inputsValue: '',
      autofillFieldActive: false,
    }
  },

  watch: {
    /**
     * V-model
     *
     * @param {string} value
     */
    value(value) {
      this.value = this.value.toUpperCase()

      this.inputsValue = this.value

      this.reset()

      // Input field is not there yet when set focus, so it's delayed.
      if (this.inputsValue.length <= 0) {
        setTimeout(() => {
          this.$refs.inputAutofill.focus()
        }, 200)
      }
    },
  },

  methods: {
    /**
     * @param {Event} event
     */
    onInputAutofill(event) {
      const inputAutofillValue = event.target.value
      const charCount = inputAutofillValue.length

      this.$refs.inputAutofill.value = ''

      const inputIndex =
        charCount < this.inputsMapping.length ? charCount : charCount - 1
      this.getRefForIndex(inputIndex)?.focus()

      this.value = inputAutofillValue
      this.value.length === this.inputsMapping.length
        ? this.validate(this.value)
        : ''

      this.$emit('input', event.target.value)
    },

    /**
     * @param {Event} event
     * @param {Element} event.target
     */
    onCodeInput({ target }) {
      // Recreate the value
      this.value = Array.from(
        Array(this.inputsMapping.length),
        (element, i) => {
          return this.getRefForIndex(i)?.value.toUpperCase() || ''
        },
      ).join('')

      this.inputsValue = this.value

      if (this.value.length === this.inputsMapping.length) {
        this.validate(this.value)
      }

      const nextInputField = target.nextElementSibling
      // Go to next input if possible
      if (nextInputField && target.value) {
        nextInputField.focus()
        nextInputField.select()
      }
    },

    /**
     * @param {string} currentIndex
     */
    onCodeBackspace(currentIndex) {
      const previousIndex = parseInt(currentIndex) - 1
      if (this.getRefForIndex(previousIndex)) {
        this.value = this.value.slice(0, -1)
        this.inputsValue = this.value
        this.$refs[previousIndex][0].focus()
      }
    },

    /**
     * Set input class as false when input is validated
     *
     * @param errors
     * @param rawErrors
     */
    afterOnInvalid(errors, rawErrors) {
      // When our back-end doesn't recognise the postal, we want to emit this on the bus
      // so we can log/track these and improve our coverage.
      if (rawErrors.pop() === ValidationError.reasonUnknownPostal) {
        this.$eventBus.emitPostalNotFoundEvent(this.country, this.value)
      }
    },

    /**
     * @returns {Array}
     */
    validationRules() {
      return ValidationRules.getRules([
        ...ValidationRules.isPostalFormat(this.country),
      ])
    },

    /**
     * @returns {*[]}
     */
    customErrorMap() {
      return [
        {
          id: ValidationError.reasonIsRequired,
          message: this.$i18n.t('field.postal.validation.required'),
        },
        {
          id: ValidationError.reasonInvalidRegex,
          message: this.$i18n.t('field.postal.validation.incorrectFormat'),
        },
        {
          id: ValidationError.reasonInvalidLength,
          message: this.$i18n.t('field.postal.validation.invalid'),
        },
        {
          id: ValidationError.reasonInvalidValue,
          message: this.$i18n.t('field.postal.validation.invalid'),
        },
      ]
    },

    /**
     * @param {number} index
     * @returns {Element | undefined}
     */
    getRefForIndex(index) {
      return this.$refs[index] && this.$refs[index][0]
    },
  },
}
</script>

<style lang="scss" scoped>
.separate-inputs {
  position: relative;

  // Safari fix - Need to reference the element
  input {
    outline: none;
  }

  .input {
    @apply border-b-2 border-primary w-9 h-12 text-center text-2xl bg-transparent appearance-none outline-0 rounded-none;

    &:visited,
    &:hover,
    &:active,
    &:focus {
      @apply outline-0;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }

    &.white-text {
      @apply text-white;
    }
  }

  &__autofill {
    @apply z-50 absolute top-0 left-0 w-full tracking-[36px] bg-transparent outline-0 pl-3.5;
    height: calc(100% - 2px);

    &.hide {
      display: none;
    }
  }

  // These -webkit-autofill pseudo classed need to be nested on the input field, it doesn't seem to work on the .input class
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px #fff inset;
  }

  input:-internal-autofill-selected,
  input:-internal-autofill-selected:hover,
  input:-internal-autofill-selected:focus {
    -webkit-box-shadow: 0 0 0 0;
  }
}
</style>
